<template>
    <div>
        <!-- Title and Logo -->
        <HeadTitle />
        <!-- end Logo and Title -->

        <!-- Panel Start here -->
        <panel
            title="DAFTAR JENIS RETRIBUSI 1"
            class="panel panel-success"
        >
            <!-- BAGIAN TOOLS LIST -->
            <b-row>
                <b-col md="12">
                    <b-card border-variant="dark" header-tag="header" footer-tag="footer">
                        <template #header>
                            <div class="pull-right">
                                <b-button class="pull-right" variant="primary" @click="create">
                                    <i class="fa fa-plus pr-1"></i> Tambah Jenis Retribusi 1
                                </b-button>
                            </div>
                            <h6 class="mb-0">Tools</h6>
                        </template>
                        
                        <!-- FILTER -->
                        <b-card-body class="p-0 m-0">
                            <b-row>
                                <b-col md="6">
                                    <b-form-group
                                        :label-cols="3"
                                        label="Filter By"
                                        class="mb-1"
                                    >
                                        <b-input-group size="md">
                                            <b-form-select
                                                v-model="filterBy"
                                                :options="fieldOptions"
                                                @change="changeFilterBy()"
                                            >
                                                <option slot="first" :value="'all'"
                                                    >All Data</option
                                                >
                                            </b-form-select>
                                        </b-input-group>
                                    </b-form-group>
                                    <b-form-group
                                        :label-cols="3"
                                        label="Filter"
                                        class="mb-0"
                                        description="Type to Search or Click Clear to Stop Searching "
                                    >
                                        <b-input-group size="md">
                                            <b-form-input
                                                v-model="filter"
                                                @keyup.enter="testFunc(filter)"
                                                placeholder="Type to Search"
                                                debounce="500"
                                            />
                                            <b-input-group-append>
                                                <b-btn
                                                    :disabled="!filter"
                                                    @click="filter = ''"
                                                    variant="info"
                                                    >Clear</b-btn
                                                >
                                            </b-input-group-append>
                                        </b-input-group>
                                    </b-form-group>
                                </b-col>
                                <b-col md="6">
                                    <b-form-group
                                        :label-cols="3"
                                        label="Sort"
                                        class="mb-1"
                                    >
                                        <b-input-group size="md">
                                            <b-form-select
                                                v-model="sortBy"
                                                :options="fieldOptions"
                                            >
                                                <option slot="first" :value="null">-- none --</option>
                                            </b-form-select>
                                            <b-form-select
                                                :disabled="!sortBy"
                                                v-model="sortDesc"
                                                slot="append"
                                            >
                                                <option :value="false">Asc</option>
                                                <option :value="true">Desc</option>
                                            </b-form-select>
                                        </b-input-group>
                                    </b-form-group>
                                </b-col>
                            </b-row>
                            <hr class="my-2" v-role:any="'Administrator|Maintenance'">
                            <b-row>
                                <b-col md="6" v-role:any="'Administrator|Maintenance'">
                                    <b-form-group
                                        :label-cols="3"
                                        label="Perangkat PD"
                                        class="mb-0"
                                    >
                                        <v-select
                                            label="nama"
                                            :reduce="nama => nama.id"
                                            :options="PemerintahDaerah.perangkat"
                                            placeholder="-Pilih Perangkat-"
                                            v-model="perangkatpd"
                                            @input="filter_InputPerangkat(perangkatpd)"
                                        ></v-select>
                                    </b-form-group>
                                </b-col>
                            </b-row>
                        </b-card-body>
                        <!-- end FILTER -->

                        <!--
                        <div class="pull-right mx-0 my-0">
                            <b-button variant="primary" to="/menu/sptrd/pendaftaran">
                                <i class="fa fa-plus pr-1"></i> Tambah SPTRD
                            </b-button>
                        </div> -->
                        <template #footer>
                            <!-- <em>Footer Slot</em> -->
                            <div class="pull-right">
                                <b-button variant="primary" @click="reload">
                                    <i class="fa fa-sync-alt pr-1"></i> Refresh
                                </b-button>
                                <!-- <b-button class="ml-2" variant="primary" to="/menu/sptrd/pendaftaran">
                                    <i class="fa fa-plus pr-1"></i> Tambah SPTRD
                                </b-button> -->
                            </div>
                        </template>
                    </b-card>
                </b-col>
            </b-row>
            <!-- end BAGIAN TOOLS LIST -->

            <hr class="my-2">
            
            <b-table
                show-empty
                small
                id="masTable"
                ref="masTable"
                head-variant="light"
                :hover="true"
                :busy.sync="isBusy"
                :items="myGetData"
                :fields="fields"
                :current-page="currentPage"
                :per-page="perPage"
                :filter="filter"
                :sort-by.sync="sortBy"
                :sort-desc.sync="sortDesc"
                bordered
            >
                <!-- 
                loading
                -------------------------------------------------- -->
                <template v-slot:table-busy>
                    <div class="text-center text-danger my-2">
                        <b-spinner class="align-middle"></b-spinner>
                        <strong>Loading...</strong>
                    </div>
                </template>
                <!-- 
                lebar kolom
                -------------------------------------------------- -->
                <template v-slot:table-colgroup="scope">
                    <col
                        v-for="field in scope.fields"
                        :key="field.key"
                        :style="{
                            width: field.key === 'actions' ? '105px' : ''
                        }"
                    />
                </template>
                <!-- 
                nomor baris
                -------------------------------------------------- -->
                <template #cell(index)="data">
                    {{ data.index + 1 }}
                </template>
                <!-- 
                isi baris
                -------------------------------------------------- -->
                <template #cell(ref_rek_5)="data">
                    <div v-if="data.item.ref_rek_5">
                        {{ data.item.ref_rek_5.nama }}
                    </div>
                </template>
                <template #cell(ref_rek_6)="data">
                    <div v-if="data.item.ref_rek_6">
                        {{ data.item.ref_rek_6.nama }}
                    </div>
                </template>
                <template #cell(perangkat_pd)="data">
                    <div v-if="data.item.perangkat_pd">
                        {{ data.item.perangkat_pd.nama }}
                    </div>
                </template>
                <template #cell(unit_pd)="data">
                    <div v-if="data.item.unit_pd">
                        {{ data.item.unit_pd.nama }}
                    </div>
                </template>
                <template #cell(id)="data">
                    {{ data.item.id }}
                </template>
                <template #cell(nama)="data">
                    <div v-html="data.item.nama"></div>
                </template>
                <!-- 
                button actions 
                -------------------------------------------------- -->
                <template v-slot:cell(actions)="data">
                    <b-dropdown
                        split
                        class="d-flex align-item-center"
                        size="sm"
                        variant="info"
                    >
                        <template #button-content>
                            <i class="fa fa-cogs pr-1"></i> Aksi
                        </template>
                        <b-dropdown-item @click="view(data.item.id)"
                            ><i class="fa fa-eye"></i>
                            Lihat</b-dropdown-item
                        >
                        <b-dropdown-item @click="edit(data.item.id)"
                            ><i class="fa fa-edit"></i>
                            Edit</b-dropdown-item
                        >
                        <b-dropdown-item
                            @click="
                                swalNotification('error', data.item.id)
                            "
                            ><i class="fa fa-trash-alt"></i>
                            Hapus</b-dropdown-item
                        >
                    </b-dropdown>
                </template>
                <!-- end button actions -->
            </b-table>

            <div class="divider"></div>

            <!-- pagination section -->
            <b-row>
                <div class="divider"></div>
                <b-col md="6" class="my-0">
                    <b-form-group
                        :label-cols="2"
                        label="Per page"
                        class="mb-0"
                    >
                        <b-form inline>
                            <b-form-select
                                :options="pageOptions"
                                style="width: 100px;"
                                v-model="perPage"
                            />
                            <label class="ml-1 mr-1">/</label>
                            <b-form-input
                                :disabled="true"
                                v-model="totalRows"
                                style="width: 100px;"
                                class="text-right"
                            />
                            <label class="ml-1">Rows</label>
                        </b-form>
                    </b-form-group>
                </b-col>
                <b-col md="6" class="my-0">
                    <b-pagination
                        v-model="currentPage"
                        :total-rows="totalRows"
                        :per-page="perPage"
                        align="fill"
                        size="md"
                        class="my-0 "
                    ></b-pagination>
                </b-col>
            </b-row>
            <!-- end pagination section -->

        </panel>
        <!-- Panel end here -->
        
        <!-- 
        Modal untuk Form view()
        -------------------------------------------------- -->
        <b-modal
            title="Detail Jenis Retribusi 1"
            v-model="showModalView"
            id="modal-view"
            hide-footer
            no-close-on-backdrop
            size="lg"
        >
            <form>
                <div class="form-group row m-3">
                    <label class="col-md-4 col-form-label font-weight-bold"
                        >Referensi Rekening 5
                    </label>
                    <div class="col-md-8">
                        <v-select
                            label="nama"
                            :options="RefRek.refRek5"
                            :reduce="(nama) => nama.id"
                            v-model="form.ref_rek_5_id"
                            @input="inputRefRek5(form.ref_rek_5_id)"
                            placeholder="--- Pilih data ---"
                            disabled
                        >
                        </v-select>
                        <em
                            v-if="form.errors.has('ref_rek_5_id')"
                            class="form-text text-danger"
                            >{{ errors.ref_rek_5_id[0] }}</em
                        >
                    </div>
                </div>
                 <div class="form-group row m-3">
                    <label class="col-md-4 col-form-label font-weight-bold"
                        >Referensi Rekening 6
                    </label>
                    <div class="col-md-8">
                        <v-select
                            label="nama"
                            :options="RefRek.refRek6"
                            :reduce="(nama) => nama.id"
                            v-model="form.ref_rek_6_id"
                            placeholder="--- Pilih data ---"
                            disabled
                        >
                        </v-select>
                    </div>
                </div>
                <div class="form-group row m-3">
                    <label class="col-md-4 col-form-label font-weight-bold"
                        >Perangkat
                    </label>
                    <div class="col-md-8">
                        <v-select
                            label="nama"
                            :options="Perangkat.perangkat"
                            :reduce="(nama) => nama.id"
                            v-model="form.perangkat_pd_id"
                            @input="inputPerangkat(form.perangkat_pd_id)"
                            placeholder="--- Pilih data ---"
                            disabled
                        >
                        </v-select>
                        <em
                            v-if="form.errors.has('perangkat_pd_id')"
                            class="form-text text-danger"
                            >{{ errors.perangkat_pd_id[0] }}</em
                        >
                    </div>
                </div>
                <div class="form-group row m-3">
                    <label class="col-md-4 col-form-label font-weight-bold"
                        >Unit
                    </label>
                    <div class="col-md-8">
                        <v-select
                            label="nama"
                            :options="Perangkat.unit"
                            :reduce="(nama) => nama.id"
                            v-model="form.unit_pd_id"
                            placeholder="--- Pilih data ---"
                            disabled
                        >
                        </v-select>
                    </div>
                </div>
                <div class="form-group row m-3">
                    <label class="col-md-4 col-form-label font-weight-bold"
                        >Jenis Retribusi
                    </label>
                    <div class="col-md-8">
                        <div class="row">
                            <div class="col-sm-12">
                                <input
                                    type="text"
                                    :class="{
                                        'form-control': true,
                                        'is-invalid': form.errors.has('nama'),
                                    }"
                                    v-model="form.nama"
                                    placeholder="Masukkan Nama Jenis Retribusi"
                                    style="text-transform: uppercase;"
                                    disabled
                                />
                                <em
                                    v-if="form.errors.has('nama')"
                                    class="form-text text-danger"
                                    >{{ errors.nama[0] }}</em
                                >
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </b-modal>

        <!-- 
        Modal untuk Form create() & edit()
        -------------------------------------------------- -->
        <b-modal
            :title="
                editMode ? 'Edit Data Jenis Retribusi 1' : 'Tambah Data Jenis Retribusi 1'
            "
            v-model="showModalForm"
            id="modal"
            hide-footer
            no-close-on-backdrop
            size="lg"
        >
            <form
                @submit.prevent="
                    editMode ? update(detailRetJenis1Mixin.id) : store()
                "
                @keydown="form.onKeydown($event)"
                autocomplete="off"
            >
                <div class="form-group row m-3">
                    <label class="col-md-4 col-form-label font-weight-bold"
                        >Referensi Rekening 5
                        <span class="text-danger">*</span>
                    </label>
                    <div class="col-md-8">
                        <v-select
                            label="nama"
                            :get-option-label="(option) => `${option.id} ${option.nama}`"
                            :options="RefRek.refRek5"
                            :reduce="(nama) => nama.id"
                            v-model="form.ref_rek_5_id"
                            @input="inputRefRek5(form.ref_rek_5_id)"
                            placeholder="--- Pilih data ---"
                        >
                            <template #option="{ id, kode, nama }">
                                <span>
                                    <strong>ID : {{ id }}</strong>
                                    <br><strong>Kode : {{ kode }}</strong>
                                    <br><div v-html="nama"></div>
                                </span>
                            </template>
                            <template #selected-option="{ id, kode, nama }">
                                <span>
                                    <strong>ID : {{ id }}</strong>
                                    <br><strong>Kode : {{ kode }}</strong>
                                    <br><div v-html="nama"></div>
                                </span>
                                <!-- <div style="display: flex; align-items: baseline">
                                    <strong>Nomor {{ nomor }} Tahun {{ tahun }}</strong>
                                    <em style="margin-left: 0.5rem"
                                    >{{ judul }}</em
                                    >
                                </div> -->
                            </template>
                        </v-select>
                        <em
                            v-if="form.errors.has('ref_rek_5_id')"
                            class="form-text text-danger"
                            >{{ errors.ref_rek_5_id[0] }}</em
                        >
                    </div>
                </div>
                 <div class="form-group row m-3">
                    <label class="col-md-4 col-form-label font-weight-bold"
                        >Referensi Rekening 6
                        <span class="text-danger">*</span>
                    </label>
                    <div class="col-md-8">
                        <v-select
                            label="nama"
                            :get-option-label="(option) => `${option.id} ${option.nama}`"
                            :options="RefRek.refRek6"
                            :reduce="(nama) => nama.id"
                            v-model="form.ref_rek_6_id"
                            placeholder="--- Pilih data ---"
                        >
                            <template #option="{ id, kode, nama }">
                                <span>
                                    <strong>ID : {{ id }}</strong>
                                    <br><strong>Kode : {{ kode }}</strong>
                                    <br><div v-html="nama"></div>
                                </span>
                            </template>
                            <template #selected-option="{ id, kode, nama }">
                                <span>
                                    <strong>ID : {{ id }}</strong>
                                    <br><strong>Kode : {{ kode }}</strong>
                                    <br><div v-html="nama"></div>
                                </span>
                            </template>
                        </v-select>
                        <em
                            v-if="form.errors.has('ref_rek_6_id')"
                            class="form-text text-danger"
                            >{{ errors.ref_rek_6_id[0] }}</em
                        >
                    </div>
                </div>
                <div class="form-group row m-3">
                    <label class="col-md-4 col-form-label font-weight-bold"
                        >Perangkat
                        <span class="text-danger">*</span>
                    </label>
                    <div class="col-md-8">
                        <v-select
                            label="nama"
                            :get-option-label="(option) => `${option.id} ${option.nama}`"
                            :options="Perangkat.perangkat"
                            :reduce="(nama) => nama.id"
                            v-model="form.perangkat_pd_id"
                            @input="inputPerangkat(form.perangkat_pd_id)"
                            placeholder="--- Pilih data ---"
                        >
                            <template #option="{ id, kode, kode_pd, nama }">
                                <span>
                                    <strong>ID : {{ id }}</strong>
                                    <br><strong>Kode : {{ kode }} / {{kode_pd}}</strong>
                                    <br><div v-html="nama"></div>
                                </span>
                            </template>
                            <template #selected-option="{ id, kode, kode_pd, nama }">
                                <span>
                                    <strong>ID : {{ id }}</strong>
                                    <br><strong>Kode : {{ kode }} / {{kode_pd}}</strong>
                                    <br><div v-html="nama"></div>
                                </span>
                            </template>
                        </v-select>
                        <em
                            v-if="form.errors.has('perangkat_pd_id')"
                            class="form-text text-danger"
                            >{{ errors.perangkat_pd_id[0] }}</em
                        >
                    </div>
                </div>
                <div class="form-group row m-3">
                    <label class="col-md-4 col-form-label font-weight-bold"
                        >Unit
                    </label>
                    <div class="col-md-8">
                        <v-select
                            label="nama"
                            :get-option-label="(option) => `${option.id} ${option.nama}`"
                            :options="Perangkat.unit"
                            :reduce="(nama) => nama.id"
                            v-model="form.unit_pd_id"
                            placeholder="--- Pilih data ---"
                        >
                            <template #option="{ id, kode, kode_pd, nama }">
                                <span>
                                    <strong>ID : {{ id }}</strong>
                                    <br><strong>Kode : {{ kode }} / {{kode_pd}}</strong>
                                    <br><div v-html="nama"></div>
                                </span>
                            </template>
                            <template #selected-option="{ id, kode, kode_pd, nama }">
                                <span>
                                    <strong>ID : {{ id }}</strong>
                                    <br><strong>Kode : {{ kode }} / {{kode_pd}}</strong>
                                    <br><div v-html="nama"></div>
                                </span>
                            </template>
                        </v-select>
                        <em
                            v-if="form.errors.has('unit_pd_id')"
                            class="form-text text-danger"
                            >{{ errors.unit_pd_id[0] }}</em
                        >
                    </div>
                </div>
                <div class="form-group row m-3">
                    <label class="col-md-4 col-form-label font-weight-bold"
                        >Jenis Retribusi
                        <span class="text-danger">*</span></label
                    >
                    <div class="col-md-8">
                        <div class="row">
                            <div class="col-sm-12">
                                <ckeditor :config="editorConfig" v-model="form.nama"></ckeditor>
                                <em
                                    v-if="form.errors.has('nama')"
                                    class="form-text text-danger"
                                    >{{ errors.nama[0] }}</em
                                >
                            </div>
                        </div>
                    </div>
                </div>
                <hr />
                <span>
                    <b-button variant="primary" type="submit">
                        <span v-if="isLoading">
                            <b-spinner
                                variant="primary"
                                label="Spinning"
                                small
                            ></b-spinner>
                        </span>
                        <i class="fa fa-save" v-else></i>
                        Simpan
                    </b-button>
                </span>
                <span class="float-right">
                    <b-button variant="outline-secondary" @click="close"
                        ><i class="fa fa-times"></i> Tutup</b-button
                    >
                </span>
            </form>
        </b-modal>

    </div>
</template>

<script>
/* eslint-disable */
// eslint-disable-next-line
// eslint-disable vue/no-unused-vars
// eslint-disable-line no-unused-vars

import Logo from "@/assets/images/logoSumut.png";
import axios from "axios";
import HeadTitle from "@/components/header/HRetribusi.vue";
import { mapState } from "vuex";
import hapusToken from "@/helper/hapusLocalStore";
import dataRetribusi from "@/helper/api/retribusi_jenis"

export default {
    mixins: [hapusToken, dataRetribusi],
    components: {
        HeadTitle,
    },
    data() {
        return {
            // selected: "1",
            imageLogo: Logo,

            // ==================================================
            // Data & Table
            // --------------------------------------------------
            // tools table
            isBusy: false,
            sortBy: null,
            sortDesc: false,
            filter: null,
            filterBy: "all",
            pageOptions: [5, 10, 15, 25],
            perPage: 10,
            currentPage: 1,
            totalRows: 0,
            // ..................................................
            perangkatpd: "all",
            role: JSON.parse(localStorage.getItem("roles"))[0],
            // --------------------------------------------------
            // table setting
            fields: [
                {
                    key: "index",
                    label: "No.",
                    tdClass: "text-right"
                },
                {
                    key: "ref_rek_5",
                    label: "REFERENSI REKENING 5",
                    sortable: false,
                },
                {
                    key: "ref_rek_6",
                    label: "REFERENSI REKENING 6",
                    sortable: false,
                },
                {
                    key: "perangkat_pd",
                    label: "PERANGKAT PD",
                    sortable: false,
                },
                {
                    key: "unit_pd",
                    label: "UNIT PD",
                    sortable: false,
                },
                {
                    key: "id",
                    label: "ID",
                    sortable: true,
                    tdClass: "text-right"
                },
                {
                    key: "nama",
                    label: "JENIS RETRIBUSI",
                    sortable: true,
                },
                {
                    key: "actions",
                    label: "opsi",
                    sortable: false,
                },
            ],
            // --------------------------------------------------
            // actions button

            // ==================================================
            // Form & FormData for CRUD
            // --------------------------------------------------
            // ...

            status_id: null,
            editMode: false,
            showModalForm: false,
            showModalView: false,
            form: new Form({
                ref_rek_5_id: '',
                ref_rek_6_id: '',
                perangkat_pd_id: '',
                unit_pd_id: '',
                nama: ''
            }),
            errors: [],
            isLoading: false,
            editorConfig: {
                removeButtons: 'Image,Table,Cut,Copy,RemoveFormat,Anchor,Source,Styles,Format,About,Blockquote',
            }
        };
    },
    computed: {
        fieldOptions() {
            // Create an options list from our fields
            return this.fields
                .filter((f) => f.sortable)
                .map((f) => {
                    return {
                        text: f.label,
                        value: f.key,
                    };
                });
        },
        ...mapState(["PemerintahDaerah", "filterTest", "RefRek", "Perangkat"]),
    },
    mounted() {
        this.$store.dispatch("RefRek/getRefRek5")
        this.$store.dispatch("Perangkat/getPerangkat")
        this.$store.dispatch("PemerintahDaerah/getPerangkat");
    },
    methods: {
        myGetData(ctx) {
            // ctx =
            // berasal dari tag <b-table></b-table>
            //this.$Progress.start();
            this.isBusy = true;
            let promise = axios.get("/api/retribusi/ret-jenis-1", {
                params: {
                    page: ctx.currentPage,
                    perpage: ctx.perPage,
                    sortby: ctx.sortBy,
                    sortdesc: ctx.sortDesc,
                    filter: ctx.filter,
                    filterby: this.filterBy,
                    perangkatpd: this.perangkatpd,
                    retjenis1: true,
                    retjenis2: true,
                    retjenis3: true,
                    rettarif: true,
                },
            });
            return promise
                .then((response) => {
                    const items = response.data.data;
                    // Data Pagination
                    // configPagination(response.data.meta);
                    this.totalRows = response.data.meta.total;
                    // Here we could override the busy state, setting isBusy to false
                    //this.$Progress.finish();
                    this.isBusy = false;
                    return items;
                })
                .catch((error) => {
                    // Here we could override the busy state, setting isBusy to false
                    //this.$Progress.fail();
                    this.isBusy = false;
                    if (error.response.status === 401) {
                        // hapusToken Mixin
                        this.clearAll();
                    }
                    // Returning an empty array, allows table to correctly handle
                    // internal busy state in case of error
                    return [];
                });
        },
        reload() {
            // with id="masTable" in tag b-table
            // this.$root.$emit('bv::refresh::table', 'masTable')
            // with property ref="masTable" in tag b-table
            this.$refs.masTable.refresh();
            localStorage.removeItem("filterView");
        },
        changeFilterBy() {
            if (this.filter) {
                this.$refs.masTable.refresh();
            }
        },
        swalNotification(swalType, id) {
            var btnClass = swalType == "error" ? "danger" : swalType;
            btnClass = swalType == "question" ? "primary" : btnClass;
            this.$swal({
                title: "Anda yakin ?",
                text: "Anda tidak akan bisa mengembalikan data!",
                type: swalType,
                showCancelButton: true,
                buttonsStyling: false,
                confirmButtonText: "Hapus",
                cancelButtonText: "Cancel",
                confirmButtonClass: "btn m-r-5 btn-" + btnClass + "",
                cancelButtonClass: "btn btn-default",
            }).then((result) => {
                if (result.isConfirmed) {
                    axios
                        .delete("/api/retribusi/ret-jenis-1/" + id)
                        .then((response) => {
                            this.$swal({
                                title: "Data Berhasil Dihapus!",
                                icon: "success",
                                type: "success",
                                showCloseButton: true,
                                showConfirmButton: false,
                                timer: 5000,
                            }).catch((error) => {
                                if (error.response.status === 401) {
                                    // hapusToken Mixin
                                    this.clearAll();
                                }
                            });
                        });
                }
                this.reload();
            });
        },
        editButton(index) {
            this.$router.push({ name: "EditNPWP", params: { id: index } });
        },
        filterStatus(number) {
            this.status_id = number;
            this.reload();
        },
        // show modal
        create() {
            this.form.reset();
            this.form.clear();
            this.editMode = false;
            this.$root.$emit("bv::show::modal", "modal");
            this.showModalForm == true;
        },
        // show modal
        edit(id) {
            this.form.reset()
            this.form.clear();
            this.editMode = true;
            this.$root.$emit("bv::show::modal", "modal");
            this.showModalForm == true;
            // detail ret jenis 1 on mixin
            this.detailRetJenis1(id)
        },
        view(id) {
            this.form.reset()
            this.form.clear()
            this.editMode = true;
            this.$root.$emit("bv::show::modal", "modal-view");
            this.showModalView == true;
            // detail ret jenis 1 on mixin
            this.detailRetJenis1(id)
        },
        close() {
            this.form.reset();
            this.$root.$emit("bv::hide::modal", "modal");
            this.showModalForm == false;
        },
        // store data ret jenis 1
        store() {
            this.isLoading = true;
            // this.form.nama = this.form.nama.toUpperCase()
            this.form.nama  = this.form.nama.replace("<p>", "<p class='mb-0'>")
            this.form
                .post("/api/retribusi/ret-jenis-1")
                .then((response) => {
                    this.isLoading = false;

                    // this.form.reset();
                    // this.form.ref_rek_5_id  = 
                    // this.form.ref_rek_6_id  = 
                    // this.form.perangkat_pd_id=
                    // this.form.unit_pd_id    = 
                    this.form.nama          = ""

                    // this.kode = {
                    //     klasifikasi: "",
                    //     urusan: "",
                    // };
                    this.$swal({
                        icon: "success",
                        title: "Data berhasil ditambah",
                        type: "success",
                        confirmButtonClass: "btn btn-info",
                        showConfirmButton: true,
                        showCloseButton: true,
                        confirmButtonText: "OKE",
                        allowOutsideClick: false,
                        timer: 5000,
                    });
                    this.reload();
                })
                .catch((error) => {
                    this.isLoading = false;
                    if (error.response.status === 422) {
                        this.errors = error.response.data;
                    } else if (error.response.status === 401) {
                        // hapusToken mixin
                        this.clearAll();
                    }
                });
        },
        // update data ret jenis 1
        update(id) {
            this.isLoading = true;
            // this.form.nama = this.form.nama.toUpperCase()
            this.form.nama  = this.form.nama.replace("<p>", "<p class='mb-0'>")
            this.form
                .put("/api/retribusi/ret-jenis-1/" + id)
                .then((response) => {
                    this.isLoading = false;
                    this.$swal({
                        icon: "success",
                        title: "Data berhasil dirubah",
                        type: "success",
                        confirmButtonClass: "btn btn-info",
                        showConfirmButton: true,
                        showCloseButton: true,
                        confirmButtonText: "OKE",
                        allowOutsideClick: false,
                    });
                    this.reload();
                })
                .catch((error) => {
                    this.isLoading = false;
                    if (error.response.status === 422) {
                        this.errors = error.response.data;
                    } else if (error.response.status === 401) {
                        // hapusToken Mixin
                        this.clearAll();
                    }
                });
        },
        inputRefRek5 (kode) {
            if (kode) {
                this.$store.dispatch("RefRek/getRefRek6", kode)
                this.form.ref_rek_6_id = ''
            } else {
                this.$store.commit("RefRek/inputRefRek6", [])
                this.form.ref_rek_6_id = ''
            }
        },
        inputPerangkat (kode) {
            if (kode) {
                this.$store.dispatch("Perangkat/getUnit", kode)
                this.form.unit_pd_id = ''
            } else {
                this.$store.dispatch("Perangkat/getUnit", [])
                this.form.unit_pd_id = ''
            }
        },

        /**
         * BAGIAN FILTER
         * --------------------------------------------------
         */
        filter_InputPerangkat(value) {
            this.perangkatpd = value;
            this.reload();
        },
    },
};
</script>

<style scoped>

</style>
